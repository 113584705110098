
import { Vue, Component } from 'vue-property-decorator'
import { FormData, DataList, OldData } from '@/types/handover'
import AddDialog from './AddDialog.vue'
import { twoDecimalNoZero } from '@/utils/validate'
import { ElForm } from 'element-ui/types/form'
import ImportData from '@/components/importTemplateData/Index.vue'

@Component({
  name: 'handoverList',
  components: { AddDialog, ImportData }
})
export default class extends Vue {
  private dateRange = []
  private searchInfo = {
    projectId: '',
    formName: '',
    productName: '',
    productModel: '',
    startTime: '',
    endTime: ''
  }

  private page = 1
  private size = 10
  private total = 0
  private loading = false
  get projectList () {
    return this.$store.state.projectList
  }

  private formData: FormData = {
    total: 0,
    dataList: []
  }

  private oldData: OldData = {
    productName: '',
    productModel: '',
    productUnit: '',
    productNum: '',
    productBak: ''
  }

  private addShow = false
  private isShowImport = false
  private editIndex: number | null = null
  private tableRules = {
    productName: [
      { required: true, message: '请输入名称', trigger: ['blur', 'change'] }
    ],
    productModel: [
      { required: true, message: '请输入规格', trigger: ['blur', 'change'] }
    ],
    productNum: [
      { required: true, message: '请输入数量', trigger: ['blur', 'change'] },
      { validator: twoDecimalNoZero, trigger: ['blur', 'change'] }
    ]
  }

  created () {
    this.getData()
  }

  // 查询
  searchData () {
    this.page = 1
    this.getData()
  }

  getData () {
    this.loading = true
    this.$axios.get<{ total: number; list: Array<DataList> }>(this.$apis.handover.selectTransferProductByPage, {
      page: this.page,
      size: this.size,
      ...this.searchInfo
    }).then(res => {
      this.total = res.total || 0
      this.formData.dataList = res.list || []
    }).finally(() => {
      this.loading = false
    })
  }

  dateRangeChange (date: any) {
    if (date) {
      this.searchInfo.startTime = date[0]
      this.searchInfo.endTime = date[1]
    } else {
      this.searchInfo.startTime = ''
      this.searchInfo.endTime = ''
    }
  }

  // 添加关闭
  closeAdd () {
    this.addShow = false
    this.getData()
  }

  // 列表编辑当条
  onEdit (row: DataList, index: number) {
    if (this.editIndex !== null) {
      this.$message.warning('请先保存！')
    } else {
      this.oldData.productName = row.productName
      this.oldData.productModel = row.productModel
      this.oldData.productUnit = row.productUnit
      this.oldData.productNum = row.productNum
      this.oldData.productBak = row.productBak
      this.editIndex = index
    }
  }

  // 保存编辑
  onSave (row: DataList) {
    (this.$refs.tableData as ElForm).validate((valid) => {
      if (valid) {
        const info = {
          productId: row.productId,
          productName: row.productName,
          productModel: row.productModel,
          productUnit: row.productUnit,
          productNum: row.productNum,
          productBak: row.productBak
        }
        this.$axios.post(this.$apis.handover.updateTransferProduct, info).then(() => {
          this.$message.success('编辑成功')
          this.editIndex = null
          this.searchData()
        })
      }
    })
  }

  // 取消编辑
  onCancel (row: DataList, index: number) {
    row.productName = this.oldData.productName
    row.productModel = this.oldData.productModel
    row.productUnit = this.oldData.productUnit
    row.productNum = this.oldData.productNum
    row.productBak = this.oldData.productBak
    row.isInput = false
    this.editIndex = null
    ;(this.$refs.tableData as ElForm).clearValidate()
    this.$set(this.formData.dataList, index, this.formData.dataList[index]) // 重新渲染表格数据
  }

  // 删除
  onDelete (row: DataList) {
    if (this.editIndex !== null) {
      this.$message.warning('请先保存！')
    } else {
      this.$confirm('确认删除吗, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.post(this.$apis.handover.deleteTransferProduct, { productId: row.productId }).then(() => {
          this.$message.success('删除成功')
          this.searchData()
        })
      })
    }
  }
}
